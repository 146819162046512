/*
 *
 */

import React from 'react';
import Section from 'components/section';
import HomeIntro from 'components/home-intro';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';


/*
 *
 */

export default function Outils () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.outils.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.outils.meta_desc' })} />
			</Helmet>
			<Section>
				<HomeIntro
					title={intl.formatMessage({ id: 'page.outils.section.about.title' })}
					subtitle={intl.formatMessage({ id: 'page.outils.section.about.subtitle' })}
					ctaText={intl.formatMessage({ id: 'page.outils.hero.ctaText' })}
					ctaLink={intl.formatMessage({ id: 'page.outils.hero.ctaLink' })}
					illustration='pack-restart'
				/>
			</Section>
			<Section spacing="no-padding">
				<iframe 
					title="Liste des outils"
					class="airtable-embed" 
					src="https://airtable.com/embed/shrMGqiwh28azOox7?backgroundColor=green&viewControls=on" 
					frameborder="0" 
					onmousewheel="" 
					width="100%" 
					height="1024"></iframe>
			</Section>
		</React.Fragment>
	);
}